/**
 * edm
 */
import Vue from 'vue'
import { Page,Input,DatePicker,locale,Table,Button,Message } from 'iview';
import lang from 'iview/dist/locale/en-US';
import $event, { EVENT_NAME } from "@/utils/event-emitter";
locale(lang)
Vue.component('Page', Page);
Vue.component('Input', Input);
Vue.component('DatePicker', DatePicker);
Vue.component('Table', Table);
Vue.component('Button', Button);
import {
    language, terminal, content, sizeList, sizeSubList, leadingList, locationList, colorList, imageTextList,edmSort,
    getEdmEditService,editEdmService,getEdmDataTemplateService
} from "@/service/edm-service";
import { classification } from '@/service/spu-group-service'
import { formatUnit,dateFormat } from '@/utils/tools'
import Header from '@/components/Header/header.vue'
import { mapMutations, mapState } from "vuex";
import StyleDown from "@/components/StyleDown/styleDown.vue";
import TypeDown from "@/components/TypeDown/typeDown.vue";
import ProductSelect from "@/components/ProductSelect/productSelect.vue";
import OffersSelect from "@/components/OffersSelect/offersSelect.vue";
import { addProductDataService } from "@/service/marketing-service";
const EdmEdit = {
    name:'EdmEdit',
    components:{
        Header,
        StyleDown,
        TypeDown,
        ProductSelect,
        OffersSelect
    },
    computed: {
        ...mapState({
            launchId: state => state.launch.launchId,  //获取状态管理投放id
            launchTitle: state => state.launch.launchTitle,  //获取状态管理投放标题
            launchName: state => state.launch.launchName,  //获取状态管理投放名称
            typeId: state => state.launch.typeId,  //获取状态管理分类id
        }),
    },
    data() {
        return {
            loadingStatus:false,
            formatUnit:formatUnit,
            selectVisible:false,
            offersVisible:false,
            edmSort:edmSort,
            selectKye:0,
            selectN:0,
            selectList:[],
            offersList:[],
            typeStatus:1,
            language:language,//语言
            terminal:terminal, //终端
            content:content, //内容
            sizeList:sizeList, //文字大小
            sizeSubList:sizeSubList, //副标题文字大小
            leadingList:leadingList, //间距大小
            locationList:locationList, //位置
            colorList:colorList, //颜色
            classification:classification,//分类
            editObj:{}, //编辑banner数据集
        }
    },
    created() {
        $event.$on(EVENT_NAME.SELECT_PRODUCT_CLOSE, () => {
            this.selectVisible = false
            this.offersVisible = false
            this.selectKye = 0
            this.selectList = []
            document.body.style.overflow = 'visible'
        })
        this.onEdmEdit().then()
    },
    methods: {
        ...mapMutations('product',['SET_SELECT_LIST']),
        onSelectContent() {
            let array = [content[0],content[2]]
            return array
        },
        /**
         * 添加图文结构体
         */
        onLaunchAdd(){
            this.editObj.en.contentEn.textList.push(imageTextList)
        },
        /**
         * 选择edm排序
         * @param brand
         */
        onSelectLevel(label){
            this.editObj.en.eby = label
            this.editObj.cn.eby = label
        },
        /**
         * 上传图片文件
         * @param event
         * @param n
         */
        onFileDataChange(event){
            const file =  event.target.files[0];
            if (file.size > 5 * 1024 * 1024) {
                Message.error('请选择5M以内的图片')
                return
            }
            this.onAddProductData(file).then()
        },
        /**
         * 上传图片文件
         * @param event
         * @param n
         */
        onImgDataChange(event,key,link){
            const file =  event.target.files[0];
            if (file.size > 5 * 1024 * 1024) {
                Message.error('请选择5M以内的图片')
                return
            }
            this.onAddImageProductData(file,key,link).then()
        },
        /**
         * 选择商品
         */
        onSelectProduct(key,n) {
            this.selectVisible = true
            this.selectKye = key
            this.selectN = n
            if(n === 1){
                this.selectList = this.editObj.en.dataList[key].products1
            }
            if(n === 2){
                this.selectList = this.editObj.en.dataList[key].products2
            }
            document.body.style.overflow = 'hidden'
        },
        /**
         * 回调选中函数
         * @param list
         */
        onEdmSelectProduct(list){
            if(this.selectN === 1){
                this.editObj.en.dataList[this.selectKye].products1 = list
            }
            if(this.selectN === 2){
                this.editObj.en.dataList[this.selectKye].products2 = list
            }
            this.selectVisible = false
            this.selectKye = 0
            this.selectN = 0
            this.selectList = []
            document.body.style.overflow = 'visible'

        },
        /**
         * 选择offers
         */
        onSelectOffers(){
            this.offersVisible = true
            this.offersList = this.editObj.en.offersIdList
            document.body.style.overflow = 'hidden'
        },
        /**
         * 回调选中offers函数
         * @param list
         */
        onOffersSelectProduct(list){
            this.editObj.en.offersIdList = list
            this.offersVisible = false
            this.offersList = []
            document.body.style.overflow = 'visible'
        },
        /**
         * 返回至列表页
         */
        onBack() {
            this.$router.back()
        },
        /**
         * 选中分类
         */
        onSignupCheck(id){
            for(let i in classification){
                if(id === classification[i].id){
                    classification[i].checkBox? classification[i].checkBox = 0: classification[i].checkBox = 1
                }
            }
            if(this.editObj.en.cidList.indexOf(id) > -1){
                this.editObj.en.cidList.splice(this.editObj.en.cidList.indexOf(id),1)
            }else{
                this.editObj.en.cidList.push(id)
            }
            console.log(this.editObj.en.cidList)
        },
        /**
         * 选择起始月日
         * @param data
         */
        onStartMonthValue(data){
            this.editObj.en.startTime = data
        },
        /**
         * 选择结束月日
         * @param data
         */
        onEndMonthValue(data){
            this.editObj.en.endTime = data
        },
        /**
         * 选择终端
         * @param id
         */
        onTerminal(id){
            this.editObj.en.osType = id
        },
        /**
         * 选择终端
         * @param id
         * @param key
         * @param content
         */
        onContent(id,key,content){
            if(content === 1){
                this.editObj.en.dataList[key].contentType1 = id
            }
            if(content === 2){
                this.editObj.en.dataList[key].contentType2 = id
            }
        },
        /**
         * 提交数据
         */
        onEditSubmit(){
            this.onEditEdm().then()
        },
        /**
         * 提交获取模版数据
         */
        onAddContentList() {
            this.onEdmDataTemplate().then()
        },
        /**
         * 获取edm edit数据
         * @returns {Promise<void>}
         */
        async onEdmEdit() {
            try {
                let params = {
                    id:this.launchId
                }
                const {code,data} = await getEdmEditService(params)
                if(code === 1){
                    this.editObj = data
                    this.editObj.en.startTime = dateFormat("YYYY-mm-dd HH:MM:SS", new Date())
                    this.editObj.en.endTime = dateFormat("YYYY-mm-dd HH:MM:SS", new Date())
                    this.editObj.en.sendTime = dateFormat("YYYY-mm-dd HH:MM:SS", new Date())
                    this.editObj.en.osType = 1

                    this.editObj.cn.startTime = dateFormat("YYYY-mm-dd HH:MM:SS", new Date())
                    this.editObj.cn.endTime = dateFormat("YYYY-mm-dd HH:MM:SS", new Date())
                    this.editObj.cn.sendTime = dateFormat("YYYY-mm-dd HH:MM:SS", new Date())
                    this.editObj.cn.osType = 1
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 上传图片文件
         */
        async onAddProductData(files) {
            try {
                let params =  new FormData()
                params.append('file',files)

                const { code,data } = await addProductDataService(params)
                if(code === 1){
                    Message.success('Success！')
                    this.editObj.en.coverImage = data.path
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 上传图片文件
         */
        async onAddImageProductData(files,key,link) {
            try {
                let params =  new FormData()
                params.append('file',files)

                const { code,data } = await addProductDataService(params)
                if(code === 1){
                    Message.success('Success！')
                    if(link === 1){
                        this.editObj.en.dataList[key].lt1Image = data.path
                    }
                    if(link === 2){
                        this.editObj.en.dataList[key].lt2Image = data.path
                    }
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 编辑edm数据
         * @returns {Promise<void>}
         */
        async onEditEdm() {
            try {
                this.loadingStatus = true
                let params = this.editObj
                    params.en.cid1 = this.typeId
                    params.cn.cid1 = this.typeId

                if(this.launchName === 'EDM A'){
                    params.en.type = 5
                    params.cn.type = 5
                }
                if(this.launchName === 'EDM B'){
                    params.en.type = 6
                    params.cn.type = 6
                }
                if(this.launchName === 'EDM C'){
                    params.en.type = 7
                    params.cn.type = 7
                }
                const { code } = await editEdmService(params)
                if(code === 1){
                    this.$router.push('/edm/list')
                }
                this.loadingStatus = false
            }catch (error){
                this.loadingStatus = false
                console.log(error)
            }
        },
        /**
         * 获取edm 模版数据
         * @returns {Promise<void>}
         */
        async onEdmDataTemplate() {
            try {
                let params = {}
                const {code,data} = await getEdmDataTemplateService(params)
                if(code === 1){
                    this.editObj.en.dataList.push(data)
                }
            }catch (error){
                console.log(error)
            }
        },
    },
    /**
     * 销毁事件
     */
    destroyed() {
        document.body.style.overflow = 'visible'
        $event.$off([EVENT_NAME.SELECT_PRODUCT_CLOSE])
    }
}
export default EdmEdit
