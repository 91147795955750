/**
 * offers选择
 */
import Vue from 'vue'
import { Spin,Icon,Page,Input } from 'iview';
Vue.component('Page', Page);
Vue.component('Input', Input);
Vue.component('Spin', Spin);
Vue.component('Icon', Icon);
import Header from '@/components/Header/header.vue'
import ProductType from '@/components/ProductType/productType.vue'
import ProductMenu from '@/components/ProductMenu/productMenu.vue'
import { mapMutations, mapState } from "vuex";
import { discountList,getOffersListService } from "@/service/spu-group-service"
import { dataUnit, timeUnit } from "@/utils/tools";
import $event, { EVENT_NAME } from "@/utils/event-emitter";
const OffersSelect = {
    name:'OffersSelect',
    props:{
        /**
         * 自定义数据
         */
        data:{
            type:Array,
        },
    },
    components:{
        Header,
        ProductType,
        ProductMenu
    },
    computed: {
        ...mapState({
            userName: state => state.userCenter.userName,  //获取状态管理用户名称
            selectList: state => state.product.selectList, //获取状态管理选择的商品
        }),
    },
    data() {
        return {
            dataUnit:dataUnit,
            timeUnit:timeUnit,
            loadingVisible:false,
            discountList:discountList,
            offersArray:[], //商品列表对象
            offersSelect:[],//选中的商品
        }
    },
    created() {
        this.offersSelect = this.data
        this.onOffersList().then()
    },
    methods: {
        ...mapMutations('product',['SET_PRODUCT_ID','SET_SELECT_LIST']),

        /**
         * 选中offers
         * @param obj
         */
        onOffersSelect(obj) {
            obj.checked = !obj.checked;
            if(this.offersSelect.length === 0){
                this.offersSelect.push(obj.id)
            }else{
                let index = this.offersSelect.findIndex((id) => id === obj.id)
                if(this.offersSelect.findIndex((id) => id === obj.id) !==-1){
                    this.offersSelect.splice(index,1)
                }else{
                    this.offersSelect.push(obj.id)
                }
            }
        },
        /**
         * 保存选择的商品id
         */
        onSave(){
            this.$emit('on-edm-select-product',this.offersSelect)
        },
        onBack(){
            $event.$emit(EVENT_NAME.SELECT_PRODUCT_CLOSE)
        },
        /**
         * 跳转列表
         * @param cid
         */
        onOffersLink(obj) {
             window.open(obj.link,'_blank')
        },
        /**
         * 拉取offers列表
         * @returns {Promise<void>}
         */
        async onOffersList() {
            try {
                this.loadingVisible = true
                let params = {}
                const { code,data } = await getOffersListService(params)
                if(code === 1){
                    for(let i in data){
                        data[i].checked = false
                    }
                    for(let i in this.data){
                        for(let j in data){
                            if(this.data[i] === data[j].id){
                                data[j].checked = true
                            }
                        }
                    }
                    this.offersArray = data
                }
                this.loadingVisible = false
            }catch (error){
                this.loadingVisible = false
                console.log(error)
            }
        },
    },
}
export default OffersSelect
