<template>
    <div class="layout-body">
        <!--s: Header-->
        <Header/>
        <!--e: Header-->

        <!--s: Type-->
        <div class="product-type">
            <div class="type-back"><a href="javascript:" @click="onBack">Back</a></div>
        </div>
        <!--e: Type-->

        <!--s: Type-->
        <div class="product-save">
            <span class="title">Offers</span>
            <div class="btn-box">
                <a href="javascript:" @click="onSave">Save</a>
            </div>
        </div>
        <!--e: Type-->


        <!--s: Body-->
        <div class="product-body">

            <!--s: List-->
            <div class="offers-list">
                <Spin fix v-if="loadingVisible">
                    <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
                    <div>Loading</div>
                </Spin>
                <ul>
                    <li :key="key" v-for="(offers,key) in offersArray" :style="offers.checked?'background-color:#e5e5e5':''" @click="onOffersSelect(offers)">
                        <img :src="offers.logoImage" class="offers-image"/>
                        <div class="offers-exclusive">
                            <span class="name" v-if="offers.exclusive!==0">Exclusive</span>
                            <span class="number" :style="offers.exclusive===0?'width:100%':''">{{ offers.saleContent }}</span>
                        </div>
                        <div class="content">
                            <div class="box" v-html="offers.saleText"></div>
                        </div>
                        <div class="code">Code: <b>{{ offers.code }}</b></div>
                        <div class="time">Expire: {{ offers.endTime }}</div>
                        <div class="show">
                            <a href="javascript:">{{ offers.visible? 'Hide' : 'Share' }} <i class="icon" :class="offers.visible?'up':'down'"></i></a>
                        </div>
                        <div class="offers-btn" v-if="!offers.visible">
                            <a href="javascript:" class="btn" @click.stop="onOffersLink(offers)">Shop<br/>Now</a>
                        </div>
                        <div class="offers-share" v-if="offers.visible">
                            <div class="box">
                                <a href="javascript:" class="face-book"></a>
                                <a href="javascript:" class="pinterest"></a>
                                <a href="javascript:" class="twitter"></a>
                                <a href="javascript:" @click="onShare('wb',offers)" class="sina"></a>
                            </div>
                            <div class="box">
                                <a href="javascript:" class="wx"></a>
                                <a href="javascript:" class="email"></a>
                                <a href="javascript:" class="icon-code"></a>
                                <a href="javascript:" class="link"></a>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <!--e: List-->
        </div>
        <!--e: Body-->

    </div>
</template>
<script>
import OffersSelect from './offersSelect'
export default OffersSelect
</script>
<style lang="less">
@import "offersSelect";
</style>
