<template>
    <div class="layout-body" v-if="editObj.en">
        <!--s: Header-->
        <Header/>
        <!--e: Header-->

        <!--s: Type-->
        <div class="product-type">
            <div class="type-back"><a href="javascript:" @click="onBack">Back</a></div>
        </div>
        <!--e: Type-->

        <!--s: Launch -->
        <div class="launch-body">

            <div class="edit-top">
                <span class="name">{{ launchName }} {{ launchId === ''?' - New':'' }}</span>
                <span class="title"><font>ID</font>{{ editObj.en.planId }}</span>
            </div>

            <div class="edit-check edit-border-bottom">
                <div class="box">
                    <ul>
                        <li :key="key" v-for="(lang,key) in language">
                            <i class="label-icon" :class="lang.checkBox?'select':'selected'"></i>
                            <span class="label-name" :class="lang.checkBox?'title':''">{{ lang.name }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="edit-check edit-data">
                <ul>
                    <li>
                        <div class="title">
                            <span class="name">Start</span>
                            <i class="type-icon"></i>
                            <DatePicker :value="editObj.en.startTime" placement="bottom-start" type="date" @on-change="onStartMonthValue" format="yyyy-MM-dd"></DatePicker>
                        </div>
                        <div class="key">{{ formatUnit(editObj.en.startTime) }}</div>
                    </li>
                    <li>
                        <div class="title">
                            <span class="name">End</span>
                            <i class="type-icon"></i>
                            <DatePicker :value="editObj.en.endTime" placement="bottom-start" type="date" @on-change="onEndMonthValue" format="yyyy-MM-dd"></DatePicker>
                        </div>
                        <div class="key">{{ formatUnit(editObj.en.endTime) }}</div>
                    </li>
                    <li>
                        <div class="title">
                            <span class="name">Send time</span>
                            <i class="type-icon"></i>
                            <DatePicker :value="editObj.en.sendTime" placement="bottom-start" type="date" @on-change="onEndMonthValue" format="yyyy-MM-dd"></DatePicker>
                        </div>
                        <div class="key">{{ formatUnit(editObj.en.sendTime) }}</div>
                    </li>
                    <li>
                        <TypeDown v-model="editObj.en.confId"  title="Send by" :width="120" :margin-right="25" :data="editObj.en.emailConfList" />
                    </li>
                    <li style="display: block">
                        <div class="label" :key="key" v-for="(edm,key) in edmSort" @click="onSelectLevel(edm.label)">
                            <i class="label-icon" :class="editObj.en.eby === edm.label?'selected':'select'"></i>
                            <span class="label-name">{{ edm.value }}</span>
                        </div>
                    </li>
                </ul>
            </div>

            <!--s: cover-->
            <div class="launch-box">
                <div class="launch-text">
                    <div class="box" style="margin-right: 30px">
                        <div class="title">
                            <span style="float:left;">Email title</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.en.emailTitle" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                    <div class="box" >
                        <div class="title">
                            <span style="float:left;">邮件标题</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.cn.emailTitle" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                </div>
                <h1 class="headline">Cover</h1>
                <div class="launch-terminal">
                    <a href="javascript:" :class="editObj.en.osType === item.id?'active':''" :key="key" v-for="(item,key) in terminal" @click="onTerminal(item.id)">{{ item.name }}</a>
                </div>

                <div class="launch-plug">
                    <StyleDown v-model="editObj.en.ctSize" disabled  title="Size" :width="70" :margin-right="25" :data="sizeSubList" />
                    <StyleDown v-model="editObj.en.ctLeading" disabled  title="Leading" :width="70" :margin-right="25" :data="leadingList"/>
                    <StyleDown v-model="editObj.en.ctLocation" disabled  title="Location" :width="70" :margin-right="25" :data="locationList"/>
                    <StyleDown v-model="editObj.en.ctColour"  title="Colour" color :width="70" :margin-right="25" :data="colorList"/>
                    <div class="launch-upload">
                        <span class="title">Cover image - 1270 x 480</span>
                        <div class="upload">
                            <span class="value">{{ editObj.en.coverImage }}</span>
                            <Button>Upload<input type="file" class="input" @change="onFileDataChange"/></Button>
                        </div>
                    </div>
                </div>
                <div class="launch-text">
                    <div class="box" style="margin-right: 30px">
                        <div class="title">
                            <span style="float:left;">Image title</span>
                            <span style="float:right;">Montserrat - Light</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.en.coverTitle" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                    <div class="box" >
                        <div class="title">
                            <span style="float:left;">图片标题</span>
                            <span style="float:right;">方正兰亭 - 细黑 / Montserrat - Light</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.cn.coverTitle" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                </div>

                <div class="launch-plug">
                    <StyleDown v-model="editObj.en.cstSize" disabled title="Size" :width="70" :margin-right="25" :data="sizeSubList"/>
                    <StyleDown v-model="editObj.en.cstLeading" disabled title="Leading" :width="70" :margin-right="25" :data="leadingList"/>
                    <StyleDown v-model="editObj.en.cstLocation" disabled title="Location" :width="70" :margin-right="25" :data="locationList"/>
                    <StyleDown v-model="editObj.en.cstColour"  title="Colour" color :width="70" :margin-right="25" :data="colorList"/>
                </div>
                <div class="launch-text">
                    <div class="box" style="margin-right: 30px">
                        <div class="title">
                            <span style="float:left;">Cover subtitle</span>
                            <span style="float:right;">Montserrat - SemiBold</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.en.coverSubTitle" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                    <div class="box" >
                        <div class="title">
                            <span style="float:left;">副标题</span>
                            <span style="float:right;">方正兰亭 - 粗黑 / Montserrat - SemiBold</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.cn.coverSubTitle" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                </div>
            </div>

            <!--s: Content-->
            <div class="launch-box launch-margin-top">
                <h1 class="headline">Contents</h1>
                <div class="launch-terminal"></div>
                <div class="launch-plug">
                    <StyleDown v-model="editObj.en.conSize" disabled title="Size" :width="70" :margin-right="25" :data="sizeSubList"/>
                    <StyleDown v-model="editObj.en.conLeading" disabled title="Leading" :width="70" :margin-right="25" :data="leadingList"/>
                    <StyleDown v-model="editObj.en.conLocation" disabled title="Location" :width="70" :margin-right="25" :data="locationList"/>
                    <StyleDown v-model="editObj.en.conColour"  title="Colour" color :width="70" :margin-right="25" :data="colorList"/>
                </div>
                <div class="launch-text">
                    <div class="box" style="margin-right: 30px">
                        <div class="title">
                            <span style="float:left;">Text</span>
                            <span style="float:right;">Montserrat - Regular</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.en.content" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                    <div class="box" >
                        <div class="title">
                            <span style="float:left;">文本</span>
                            <span style="float:right;">方正兰亭 - 细黑 / Montserrat - Regular</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.cn.content" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                </div>
                <div class="launch-image-list" :key="key" v-for="(item,key) in editObj.en.dataList">
                    <h1 class="headline" style="margin-top: 20px" v-if="launchName == 'EDM A'">Group {{ key+1 }}</h1>
                    <div class="launch-plug" v-if="launchName == 'EDM A'">
                        <StyleDown v-model="item.cSize" disabled title="Size" :width="70" :margin-right="25" :data="sizeSubList"/>
                        <StyleDown v-model="item.cLeading" disabled title="Leading" :width="70" :margin-right="25" :data="leadingList"/>
                        <StyleDown v-model="item.cLocation" disabled title="Location" :width="70" :margin-right="25" :data="locationList"/>
                        <StyleDown v-model="item.cColour"  title="Colour" color :width="70" :margin-right="25" :data="colorList"/>
                    </div>
                    <div class="launch-text" v-if="launchName == 'EDM A'">
                        <div class="box" style="margin-right: 30px">
                            <div class="title">
                                <span style="float:left;">Chapter Title</span>
                                <span style="float:right;">Montserrat - ExtraBold</span>
                            </div>
                            <div class="text">
                                <Input v-model="item.cTitle" type="textarea" :rows="2"></Input>
                            </div>
                        </div>
                        <div class="box" >
                            <div class="title">
                                <span style="float:left;">章节标题</span>
                                <span style="float:right;">方正兰亭 - 特黑 / Montserrat - ExtraBold</span>
                            </div>
                            <div class="text">
                                <Input v-model="item.cTitleCn" type="textarea" :rows="2"></Input>
                            </div>
                        </div>
                    </div>

                    <h1 class="headline">{{ launchName == 'EDM C'?'Part1':'Link 1' }}</h1>
                    <div class="launch-plug">
                        <StyleDown v-model="item.lt1Size" disabled title="Size" :width="70" :margin-right="25" :data="sizeSubList"/>
                        <StyleDown v-model="item.lt1Leading" disabled title="Leading" :width="70" :margin-right="25" :data="leadingList"/>
                        <StyleDown v-model="item.lt1Location" disabled title="Location" :width="70" :margin-right="25" :data="locationList"/>
                        <StyleDown v-model="item.lt1Colour"  title="Colour" color :width="70" :margin-right="25" :data="colorList"/>
                        <div class="launch-upload margin-bottom" v-if="launchName != 'EDM C'">
                            <span class="title">Image - 250 x 250</span>
                            <div class="upload">
                                <span class="value">{{ item.lt1Image }}</span>
                                <Button>Upload<input type="file" class="input" @change="onImgDataChange($event,key,1)"/></Button>
                            </div>
                        </div>
                    </div>
                    <div class="launch-text" v-if="launchName != 'EDM C'">
                        <div class="box" style="margin-right: 30px">
                            <div class="title">
                                <span style="float:left;">Title</span>
                                <span style="float:right;">Montserrat - Bold</span>
                            </div>
                            <div class="text">
                                <Input v-model="item.lt1Title" type="textarea" :rows="2"></Input>
                            </div>
                        </div>
                        <div class="box">
                            <div class="title">
                                <span style="float:left;">标题</span>
                                <span style="float:right;">方正兰亭 - 粗黑 / Montserrat - Bold</span>
                            </div>
                            <div class="text">
                                <Input v-model="item.lt1TitleCn" type="textarea" :rows="2"></Input>
                            </div>
                        </div>
                    </div>
                    <div class="launch-text" v-if="launchName == 'EDM C'">
                        <div class="box" style="margin-right: 30px">
                            <div class="title">
                                <span style="float:left;">Chapter Title</span>
                                <span style="float:right;">Montserrat - ExtraBold</span>
                            </div>
                            <div class="text">
                                <Input v-model="item.lt1Title" type="textarea" :rows="2"></Input>
                            </div>
                        </div>
                        <div class="box">
                            <div class="title">
                                <span style="float:left;">章节标题</span>
                                <span style="float:right;">方正兰亭 - 特黑 / Montserrat - ExtraBold</span>
                            </div>
                            <div class="text">
                                <Input v-model="item.lt1TitleCn" type="textarea" :rows="2"></Input>
                            </div>
                        </div>
                    </div>
                    <div class="launch-plug" v-if="launchName != 'EDM C'">
                        <StyleDown v-model="item.lc1Size" disabled title="Size" :width="70" :margin-right="25" :data="sizeSubList"/>
                        <StyleDown v-model="item.lc1Leading" disabled title="Leading" :width="70" :margin-right="25" :data="leadingList"/>
                        <StyleDown v-model="item.lc1Location" disabled title="Location" :width="70" :margin-right="25" :data="locationList"/>
                        <StyleDown v-model="item.lc1Colour"  title="Colour" color :width="70" :margin-right="25" :data="colorList"/>
                    </div>
                    <div class="launch-text" v-if="launchName != 'EDM C'">
                        <div class="box" style="margin-right: 30px">
                            <div class="title">
                                <span style="float:left;">Text</span>
                                <span style="float:right;">Montserrat - Regular</span>
                            </div>
                            <div class="text">
                                <Input v-model="item.lc1Content" type="textarea" :rows="2"></Input>
                            </div>
                        </div>
                        <div class="box" >
                            <div class="title">
                                <span style="float:left;">文本</span>
                                <span style="float:right;">方正兰亭 - 细黑 / Montserrat - Regular</span>
                            </div>
                            <div class="text">
                                <Input v-model="item.lc1ContentCn" type="textarea" :rows="2"></Input>
                            </div>
                        </div>
                    </div>
                    <div class="launch-terminal" v-if="launchName != 'EDM C'">
                        <a href="javascript:" :class="item.contentType1 === tab.id?'select':''" :key="index" v-for="(tab,index) in onSelectContent()" @click="onContent(tab.id,key,1)">{{ tab.name }}</a>
                    </div>
                    <!--s: Link-->
                    <div class="launch-link" v-if="launchName != 'EDM C' && item.contentType1 === 1">
                        <div class="box">
                            <span class="title">Link</span>
                            <div class="input-box">
                                <input v-model="item.link1" type="text" class="input" placeholder="URL"/>
                            </div>
                        </div>
                    </div>
                    <!--e: Link-->
                    <!--s: Selection-->
                    <div class="launch-selection" v-if="launchName != 'EDM C' && item.contentType1 === 3">
                        <div class="launch-select-btn" style="margin-top: 0px">
                            <a href="javascript:" class="select" @click="onSelectProduct(key,1)">Select / Edit Product List</a>
                        </div>
                    </div>
                    <div class="launch-selection" v-if="launchName == 'EDM C'">
                        <div class="launch-select-btn" style="margin-top: 0px">
                            <a href="javascript:" class="select" @click="onSelectProduct(key,1)">Select / Edit Product List</a>
                        </div>
                    </div>
                    <!--e: Selection-->

                    <h1 class="headline" style="margin-top: 20px">{{ launchName == 'EDM C'?'Part2':'Link 2' }}</h1>
                    <div class="launch-plug">
                        <StyleDown v-model="item.lt2Size" disabled title="Size" :width="70" :margin-right="25" :data="sizeSubList"/>
                        <StyleDown v-model="item.lt2Leading" disabled title="Leading" :width="70" :margin-right="25" :data="leadingList"/>
                        <StyleDown v-model="item.lt2Location" disabled title="Location" :width="70" :margin-right="25" :data="locationList"/>
                        <StyleDown v-model="item.lt2Colour"  title="Colour" color :width="70" :margin-right="25" :data="colorList"/>
                        <div class="launch-upload margin-bottom" v-if="launchName != 'EDM C'">
                            <span class="title">Image - 250 x 250</span>
                            <div class="upload">
                                <span class="value">{{ item.lt2Image }}</span>
                                <Button>Upload<input type="file" class="input" @change="onImgDataChange($event,key,2)"/></Button>
                            </div>
                        </div>
                    </div>
                    <div class="launch-text" v-if="launchName != 'EDM C'">
                        <div class="box" style="margin-right: 30px">
                            <div class="title">
                                <span style="float:left;">Title</span>
                                <span style="float:right;">Montserrat - Bold</span>
                            </div>
                            <div class="text">
                                <Input v-model="item.lt2Title" type="textarea" :rows="2"></Input>
                            </div>
                        </div>
                        <div class="box" >
                            <div class="title">
                                <span style="float:left;">标题</span>
                                <span style="float:right;">方正兰亭 - 粗黑 / Montserrat - Bold</span>
                            </div>
                            <div class="text">
                                <Input v-model="item.lt2TitleCn" type="textarea" :rows="2"></Input>
                            </div>
                        </div>
                    </div>
                    <div class="launch-text" v-if="launchName == 'EDM C'">
                        <div class="box" style="margin-right: 30px">
                            <div class="title">
                                <span style="float:left;">Chapter Title</span>
                                <span style="float:right;">Montserrat - ExtraBold</span>
                            </div>
                            <div class="text">
                                <Input v-model="item.lt2Title" type="textarea" :rows="2"></Input>
                            </div>
                        </div>
                        <div class="box" >
                            <div class="title">
                                <span style="float:left;">章节标题</span>
                                <span style="float:right;">方正兰亭 - 特黑 / Montserrat - ExtraBold</span>
                            </div>
                            <div class="text">
                                <Input v-model="item.lt2TitleCn" type="textarea" :rows="2"></Input>
                            </div>
                        </div>
                    </div>
                    <div class="launch-plug" v-if="launchName != 'EDM C'">
                        <StyleDown v-model="item.lc2Size" disabled title="Size" :width="70" :margin-right="25" :data="sizeSubList"/>
                        <StyleDown v-model="item.lc2Leading" disabled title="Leading" :width="70" :margin-right="25" :data="leadingList"/>
                        <StyleDown v-model="item.lc2Location" disabled title="Location" :width="70" :margin-right="25" :data="locationList"/>
                        <StyleDown v-model="item.lc2Colour"  title="Colour" color :width="70" :margin-right="25" :data="colorList"/>
                    </div>
                    <div class="launch-text" v-if="launchName != 'EDM C'">
                        <div class="box" style="margin-right: 30px">
                            <div class="title">
                                <span style="float:left;">Text</span>
                                <span style="float:right;">Montserrat - Regular</span>
                            </div>
                            <div class="text">
                                <Input v-model="item.lc2Content" type="textarea" :rows="2"></Input>
                            </div>
                        </div>
                        <div class="box" >
                            <div class="title">
                                <span style="float:left;">文本</span>
                                <span style="float:right;">方正兰亭 - 细黑 / Montserrat - Regular</span>
                            </div>
                            <div class="text">
                                <Input v-model="item.lc2ContentCn" type="textarea" :rows="2"></Input>
                            </div>
                        </div>
                    </div>
                    <div class="launch-terminal" v-if="launchName != 'EDM C'">
                        <a href="javascript:" :class="item.contentType2 === tab.id?'select':''" :key="index" v-for="(tab,index) in onSelectContent()" @click="onContent(tab.id,key,2)">{{ tab.name }}</a>
                    </div>
                    <!--s: Link-->
                    <div class="launch-link" v-if="launchName != 'EDM C' && item.contentType2 === 1">
                        <div class="box">
                            <span class="title">Link</span>
                            <div class="input-box">
                                <input v-model="item.link2" type="text" class="input" placeholder="URL"/>
                            </div>
                        </div>
                    </div>
                    <!--e: Link-->
                    <!--s: Selection-->
                    <div class="launch-selection" v-if="launchName != 'EDM C' && item.contentType2 === 3">
                        <div class="launch-select-btn" style="margin-top: 0px">
                            <a href="javascript:" class="select" @click="onSelectProduct(key,2)">Select / Edit Product List</a>
                        </div>
                    </div>
                    <div class="launch-selection" v-if="launchName == 'EDM C'">
                        <div class="launch-select-btn" style="margin-top: 0px">
                            <a href="javascript:" class="select" @click="onSelectProduct(key,2)">Select / Edit Product List</a>
                        </div>
                    </div>
                    <!--e: Selection-->
                </div>
            </div>
            <!--e: Content-->

            <div class="launch-top">
                <a href="javascript:" class="launch-tab-add" @click="onAddContentList">
                    <Icon custom="launch-add"></Icon>
                </a>
            </div>
            <div class="launch-select-btn" style="margin-top: 0px">
                <h1 class="headline" style="margin-top: 20px">Offers</h1>
                <a href="javascript:" class="select" @click="onSelectOffers">Select offer List</a>
            </div>
            <!--s: Btn-->
            <div class="launch-btn-edit">
                <div class="box">
                    <Button style="margin-left: 30px" :loading="loadingStatus" @click="onEditSubmit">Save</Button>
                </div>
            </div>
            <!--s: Btn-->

        </div>
        <!--e: Launch -->

        <!--s: Modal-->
        <div class="layout-select-product" v-if="selectVisible">
            <ProductSelect :data="selectList" @on-edm-select-product="onEdmSelectProduct"/>
        </div>
        <!--e: Modal-->

        <!--s: Modal-->
        <div class="layout-select-product" v-if="offersVisible">
            <OffersSelect :data="offersList" @on-edm-select-product="onOffersSelectProduct"/>
        </div>
        <!--e: Modal-->

    </div>
</template>
<script>
import EdmEdit from './edmEdit'
export default EdmEdit
</script>
<style lang="less">
@import "edmEdit";
</style>
